import React, {useEffect} from 'react';
import { MetroSpinner } from "react-spinners-kit";

import { getTranslate } from "utils/translate";
import '../styles.scss';

const PrintItem = ({item, currentTab, setNavigationTab, editedMode, toggleWarningModal, getPrintItem, currentItem, language, preselectPrintItem}) => {
  
  useEffect(() => {
    /* only check the first time the component is loaded */
    if(item && preselectPrintItem && preselectPrintItem === item.itemId) {      
      getItem();  
    }        
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  if (!item) return;
  const getStatus = (process) => {
    if (process === "regulatoryCheck" && item.itemStatus === 'ra_check' && item.regulatoryCheck.status === 0) return 'label label--process';
    if (process === "technicalCheck" && item.itemStatus === 'technical_check' && item.regulatoryCheck.status === 2 && item.technicalCheck.status === 0) return 'label label--process';
    if (process === "technicalCheck" && item.technicalCheck.acceptErrors) return 'label label--done label--warning';
    if (process === "regulatoryCheck" && item.regulatoryCheck.noLegalCheck) return 'label  label--done label--skipped';
    if (process === "regulatoryCheck" && item.regulatoryCheck.acceptErrors) return 'label  label--done label--warning';
    switch (item[process].status) {
      case 0:
        return 'label label--disabled';
      case 1:
        return 'label label--active';
      case 2:
        return 'label label--done';
      default:
        return 'label label--disabled';
    }
  };  

  const getItem = () => {
    if (editedMode) {
       toggleWarningModal({
        warningModalOpen: true,
        nextTab: currentTab,
        nextItem: item.itemId,
      });
      return null
    }

    window.scrollTo({
      top: 100,
      behavior: "smooth"
    });

    getPrintItem(item.itemId);
  };

  const getTab = (e) => {
    e.stopPropagation();
    if (editedMode) {
       toggleWarningModal({
        warningModalOpen: true,
        nextTab: e.target.name
      })
    }
    const name = e.target.name;
    getItem();
    if (name) {
      setTimeout(() => setNavigationTab(name), 200)
    }
  };

  return (
    <div
      className={ `print-item 
                    ${ item.itemStatus === 'processing' && 'is-processing' }
                    ${ item.itemStatus === 'released' && 'is-released' }
                    ${ currentItem && currentItem.itemId === item.itemId && 'is-active' }`
      }
      onClick={ getItem }>
      <div style={ {position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: 2} }>
        <MetroSpinner size={ 40 } color="#fff" loading={ item.itemStatus === 'processing' }/>
      </div>
      <div className="print-item__text">
        <p className="t t--16 t--bold">{ item.itemHeader }</p>
        <p className="t t--16">{ item.itemId } - { item.itemName }</p>
      </div>
      <div className="labels">
        <button onClick={ getTab } name="fileUpload" className={ getStatus('fileUpload') }>
          { getTranslate(language, 'printList.fileUpload') }
        </button>
        <button onClick={ getTab } name="printSpecification" className={ getStatus('printSpecification') }>
          { getTranslate(language, 'printList.printExecution') }
        </button>
        <button onClick={ getTab } name="regulatoryCheck" className={ getStatus('regulatoryCheck') }>
          { getTranslate(language, 'printList.legalCheck') }
        </button>
        <button onClick={ getTab } name="technicalCheck" className={ getStatus('technicalCheck') }>
          { getTranslate(language, 'printList.technicalCheck') }
        </button>
        <button onClick={ getTab } name="releaseCheck" className={ getStatus('releaseCheck') }>
          { getTranslate(language, 'printList.printApproval') }
        </button>
      </div>
    </div>
  )
};

export default PrintItem;
