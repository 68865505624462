import React from 'react';
import { getTranslate } from "utils/translate";
import './styles.scss';

const Tooltip = ({type, acceptAction, cancelAction, language, text, style}) => {
  return (
    <div
      className={`tooltip ${type === 'warning' ? 'tooltip--warning' : 'tooltip--info'}`}
      style={style}>
      <p className="tooltip__title">
        {type === 'warning' ? getTranslate(language, 'errorTooltip.warning') : 'Hinwise'}
      </p>
      <p className="t t--14 t--white t--bold">{text}</p>

      { type === 'warning' && (
        <div className="tooltip__actions" onClick={cancelAction}>
          <button className="tooltip__action tooltip__action--cancel">
            { getTranslate(language, 'errorTooltip.cancel') }
          </button>
          <button className="tooltip__action" onClick={acceptAction}>
            { getTranslate(language, 'errorTooltip.confirm') }
          </button>
        </div>
      )}

      { type === 'info' && (
        <div className="tooltip__actions">
          <button onClick={acceptAction} className="tooltip__action tooltip__action--round">
            Okay!
          </button>
        </div>
      )}
    </div>
  )
};

export default Tooltip;
