import React from 'react';
import { getTranslate } from "utils/translate";
import './styles.scss';

const Footer = ({language}) => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__links">
          <a
            className="footer__link t--12"
            target="_blank"
            rel="noopener noreferrer"
            href={ getTranslate(language, 'footerLinks.agb.link') }
          >
            { getTranslate(language, 'footerLinks.agb.title') } |{ " " }
          </a>
          <a
            className="footer__link t--12"
            target="_blank"
            rel="noopener noreferrer"
            href={ getTranslate(language, 'footerLinks.dataProtection.link') }
          >
            { getTranslate(language, 'footerLinks.dataProtection.title') } |{ " " }
          </a>
          <a
            className="footer__link t--12"
            target="_blank"
            rel="noopener noreferrer"
            href={ getTranslate(language, 'footerLinks.imprint.link') }
          >
            { getTranslate(language, 'footerLinks.imprint.title') }
          </a>
        </div>
        <span className="t--white t--12">Copyright 2020 IQ Digital Health GmbH</span>
      </div>
    </div>
  )
};

export default Footer;
