import { createRequestTypes, action } from '../utils/actions';
import { REQUEST, SUCCESS, FAILURE } from '../utils/constants';

export const GET_PRINT_ORDERS = createRequestTypes('GET_PRINT_ORDERS');
export const GET_ALL_PRINT_ITEMS = createRequestTypes('GET_ALL_PRINT_ITEMS');
export const GET_PRINT_ITEM = createRequestTypes('GET_PRINT_ITEM');
export const SET_ITEM_FILE_BLOB = createRequestTypes('SET_ITEM_FILE_BLOB');
export const UPDATE_PRINT_EXECUTION = createRequestTypes('UPDATE_PRINT_EXECUTION');
export const ADD_FILE_COMMENT = createRequestTypes('ADD_FILE_COMMENT');
export const ACCEPT_REGULATORY_CHECK_WITH_ERRORS = createRequestTypes('ACCEPT_REGULATORY_CHECK_WITH_ERRORS');
export const ACCEPT_TECH_CHECK_WITH_ERRORS = createRequestTypes('ACCEPT_TECH_CHECK_WITH_ERRORS');
export const RELEASE_PRINT_FILE_ITEM = createRequestTypes('RELEASE_PRINT_FILE_ITEM');
export const DECLINE_PROOF_FILE = createRequestTypes('DECLINE_PROOF_FILE');
export const TOGGLE_WARNING_MODAL = createRequestTypes('TOGGLE_WARNING_MODAL');
export const TOGGLE_SUCCESS_MODAL = createRequestTypes('TOGGLE_SUCCESS_MODAL');
export const SET_EDITED_MODE = createRequestTypes('SET_EDITED_MODE');
export const RESET_PRINT_ITEMS = 'RESET_PRINT_ITEMS';

export const getPrintOrders = {
  request: (data) => {
    return action(GET_PRINT_ORDERS[REQUEST], { data })
  },
  success: (payload) => action(GET_PRINT_ORDERS[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRINT_ORDERS[FAILURE], { payload }),
};

export const getAllPrintItems = {
  request: (id) => action(GET_ALL_PRINT_ITEMS[REQUEST], { id }),
  success: (payload) => action(GET_ALL_PRINT_ITEMS[SUCCESS], { payload }),
  failure: (payload) => action(GET_ALL_PRINT_ITEMS[FAILURE], { payload }),
};

export const getPrintItem = {
  request: (id) => action(GET_PRINT_ITEM[REQUEST], { id }),
  success: (payload) => action(GET_PRINT_ITEM[SUCCESS], { payload }),
  failure: (payload) => action(GET_PRINT_ITEM[FAILURE], { payload }),
};

export const updatePrintExecution = {
  request: (data) => action(UPDATE_PRINT_EXECUTION[REQUEST], { data }),
  success: (payload) => action(UPDATE_PRINT_EXECUTION[SUCCESS], { payload }),
  failure: (payload) => action(UPDATE_PRINT_EXECUTION[FAILURE], { payload }),
};

export const addFileComment = {
  request: (data) => action(ADD_FILE_COMMENT[REQUEST], { data }),
  success: (payload) => action(ADD_FILE_COMMENT[SUCCESS], { payload }),
  failure: (payload) => action(ADD_FILE_COMMENT[FAILURE], { payload }),
};

export const acceptRegulatoryCheckWithErrors = {
  request: (data) => action(ACCEPT_REGULATORY_CHECK_WITH_ERRORS[REQUEST], { data }),
  success: (payload) => action(ACCEPT_REGULATORY_CHECK_WITH_ERRORS[SUCCESS], { payload }),
  failure: (payload) => action(ACCEPT_REGULATORY_CHECK_WITH_ERRORS[FAILURE], { payload }),
};

export const acceptTechCheckWithErrors = {
  request: (data) => action(ACCEPT_TECH_CHECK_WITH_ERRORS[REQUEST], { data }),
  success: (payload) => action(ACCEPT_TECH_CHECK_WITH_ERRORS[SUCCESS], { payload }),
  failure: (payload) => action(ACCEPT_TECH_CHECK_WITH_ERRORS[FAILURE], { payload }),
};

export const declineProofFile = {
  request: (data) => action(DECLINE_PROOF_FILE[REQUEST], { data }),
  success: (payload) => action(DECLINE_PROOF_FILE[SUCCESS], { payload }),
  failure: (payload) => action(DECLINE_PROOF_FILE[FAILURE], { payload }),
};

export const releasePrintFileItem = {
  request: (data) => action(RELEASE_PRINT_FILE_ITEM[REQUEST], { data }),
  success: (payload) => action(RELEASE_PRINT_FILE_ITEM[SUCCESS], { payload }),
  failure: (payload) => action(RELEASE_PRINT_FILE_ITEM[FAILURE], { payload }),
};

export const setItemFile = (payload) => ({
  type: SET_ITEM_FILE_BLOB,
  payload
});

export const setEditedMode = (payload) => ({
  type: SET_EDITED_MODE,
  payload
});

export const toggleWarningModal = (payload) => {
  getPrintItem.request({ itemId: payload.nextItem });
  return ({
    type: TOGGLE_WARNING_MODAL,
    payload
  })
};

export const resetPrintItems = (payload) => {
  return ({
    type: RESET_PRINT_ITEMS,
    payload
  })
};
