import { fork, all, call, put } from 'redux-saga/effects';

import {
  watchGetAllPrintItems,
  watchGetPrintItem,
  watchAcceptRegulatoryCheckWithErrors,
  watchUpdatePrintExecution,
  watchReleasePrintFileItem,
  watchAcceptTechCheckWithErrors,
  watchDeclineProofFile,
  watchAddFileComment,
  watchGetPrintOrders,
  watchAddFileCommentSuccess,
  watchUpdatePrintExecutionSuccess
} from './Items';

export function* apiRequest(entity, apiFn, action) {
  const { id, data } = action;
  const {response, error} = id ? yield call(apiFn, id, data) : yield call(apiFn, data)
  if(!error)
    yield put( entity.success(response) );
  else
    yield put( entity.failure(error) )
}

export default function* root() {
  yield all([
    fork(watchGetPrintOrders),
    fork(watchGetAllPrintItems),
    fork(watchAcceptTechCheckWithErrors),
    fork(watchGetPrintItem),
    fork(watchUpdatePrintExecution),
    fork(watchReleasePrintFileItem),
    fork(watchAddFileComment),
    fork(watchDeclineProofFile),
    fork(watchAcceptRegulatoryCheckWithErrors),
    fork(watchAddFileCommentSuccess),
    fork(watchUpdatePrintExecutionSuccess)
  ])
}
