import React from 'react';

import InfoBlock from "./InfoBlock";
import ContentBlock from "./ContentBlock";
import Navigation from "./Navigation";

import './styles.scss';

const PrintFlow = ({ history, isOrdersPage }) => {
  return (
    <div className="print-flow">
      <InfoBlock history={history} />
      <ContentBlock history={history} isOrdersPage={isOrdersPage} />
      {!isOrdersPage && <Navigation />}
    </div>
  )
};

export default PrintFlow;
