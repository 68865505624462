import React, {useState, useEffect} from 'react';
import './styles.scss';

const RemarkField = ({ name, value, onChange, maxLength, className = "content-block__textarea", placeholder, disabled } ) => {

  const [charCount, setCharCount] = useState(0);

  useEffect(() => {    
    if(value && value.length > 0) {
        setCharCount(value.length);
    }
  }, [value]);

  const onChangeHandler = (event) => {
    if(maxLength > 0) {        
      setCharCount(event.target.value.length);              
    } 
    onChange(event);
  }
  
  const onPasteHandler = (event) => {
    const pastedText = (event.clipboardData || window.clipboardData).getData('text');
    if(pastedText && pastedText.length > maxLength) {
      // for now don't show a message, just the pill--max changes if limit is exceeded
    }
  }

  // build attributes that might or might not be defined
  let dynAttributes = {};
  if(maxLength) {
    dynAttributes['maxLength'] = String(maxLength);
  }
  if(disabled) {
    dynAttributes['disabled'] = true;
  }
  if(placeholder) {
    dynAttributes['placeholder'] = placeholder;
  }

  return (   
    <div className="remark-field"> 
      <textarea
        name={name} 
        className={className}
        value={value}        
        {...dynAttributes}
        onChange={ (event) => onChangeHandler(event) }
        onPaste={ (event) => onPasteHandler(event) }
      />
      { maxLength && !disabled && 
        <div className={"t--11 remark-field__counter-pill" + (charCount === maxLength ? " remark-field__counter-pill--max" : "")}>
          {charCount}/{maxLength}
        </div>
      }
    </div>
  )
};
  
export default RemarkField;