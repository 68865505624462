import * as ActionTypes from 'actions/Items';

const initialState = {
  printItems: null,
  printFile: null,
  editedMode: false,
  warningModalOpen: false,
  nextItem: null,
  nextTab: null,
  printItem: {},
  language: 'de'
};

export default function reducer(state = initialState, action) {
  const updatePrintItems = () => {
    const itemIndex = state.printItems.printFileItems.findIndex(el => el.itemId === action.payload.itemId);
    const newPrintItems = [...state.printItems.printFileItems];
    newPrintItems[itemIndex] = action.payload;
    return newPrintItems;
  };
  switch (action.type) {
    case (ActionTypes.GET_PRINT_ORDERS.SUCCESS):
      return {
        ...state,
        customer: action.payload.customer,
        printOrders: action.payload,
        language: action.payload.customer.languageId.slice(0, 2)
      };
    case (ActionTypes.GET_ALL_PRINT_ITEMS.SUCCESS):
      return {
        ...state,
        customer: action.payload.customer,
        printItems: action.payload,
        language: action.payload.customer.languageId.slice(0, 2)
      };
    case (ActionTypes.GET_PRINT_ITEM.SUCCESS):
      return {
        ...state,
        item: action.payload,
        printItems: {...state.printItems, printFileItems: updatePrintItems()}
      };
    case (ActionTypes.RESET_PRINT_ITEMS):
      console.log('reset')
      return {
        ...state,
        item: null,
        printItems: null
      };
   case (ActionTypes.ADD_FILE_COMMENT.SUCCESS):
      return {
        ...state,
        item: action.payload,
        successModalOpen: true,
        printItems: {...state.printItems, printFileItems: updatePrintItems()}
      };

    case (ActionTypes.UPDATE_PRINT_EXECUTION.SUCCESS):
      return {
        ...state,
        item: action.payload,
        successModalOpen: true,
        printItems: {...state.printItems, printFileItems: updatePrintItems()}
      };

    case (ActionTypes.SET_ITEM_FILE_BLOB):
      return { ...state,  printFile: action.payload };
    case (ActionTypes.ACCEPT_REGULATORY_CHECK_WITH_ERRORS.SUCCESS):
      return {
        ...state,
        item: action.payload,
        printItems: {...state.printItems, printFileItems: updatePrintItems()}
      };

    case (ActionTypes.ACCEPT_TECH_CHECK_WITH_ERRORS.SUCCESS):
      return {
        ...state,
        item: action.payload,
        printItems: {...state.printItems, printFileItems: updatePrintItems()}
      };

    case (ActionTypes.RELEASE_PRINT_FILE_ITEM.SUCCESS):
      return {
        ...state,
        item: action.payload,
        printItems: {...state.printItems, printFileItems: updatePrintItems()}
      };

    case (ActionTypes.DECLINE_PROOF_FILE.SUCCESS):
      return {
        ...state,
        item: action.payload,
        printItems: {...state.printItems, printFileItems: updatePrintItems()}
      };

    case (ActionTypes.SET_EDITED_MODE):
      return {
        ...state,
        editedMode: action.payload
      };
    case (ActionTypes.TOGGLE_WARNING_MODAL):
      return {
        ...state,
        nextTab: action.payload.nextTab,
        nextItem: action.payload.nextItem,
        warningModalOpen: action.payload.warningModalOpen
      };
    default:
      return state;
  }

}
