import React, { Fragment } from 'react';
import { connect } from "react-redux";
import axios from "axios";

import { getAllPrintItems, getPrintItem, setItemFile } from "actions/Items";
import api from "utils/api";
import { getGuidFromPath } from "utils/helpers";
import '../styles.scss';

import Dropzone from 'react-dropzone'
import ProgressModal from "../ProgressModal";

class DropZone extends React.Component {
  state = {
    files: [],
    currentItem: null,
    isUploaded: false,
    loaded: 0,
    total: 0
  };

  componentDidMount() {
    const {currentItem} = this.props;
    this.setState({
      files: [],
      loaded: 0,
      total: 0,
      isUploaded: currentItem && currentItem.fileUpload.fileName,
    })
  }

  componentDidUpdate(prevProps) {
    const {currentItem} = this.props;
    if (currentItem.itemId !== prevProps.currentItem.itemId) {
      this.setState({
        files: [],
        loaded: 0,
        total: 0,
        isUploaded: currentItem.fileUpload.fileName,
      })
    }
  }

  componentWillUnmount() {
    axios.interceptors.request.eject(this.interceptor);

    this.setState({
      files: []
    })
  }

  interceptor = axios.interceptors.request.use(
    (config) => {
      config.onUploadProgress = (progressEvent) => {

        this.setState({
          total: (progressEvent.total / 1000000).toFixed(1),
          loaded: (progressEvent.loaded / 1000000).toFixed(1),
        });
      };
      return config;
    },
    error => {
      return Promise.reject(error);
    });

  async onDrop(files) {
    this.props.showCommentWindow();

    this.setState({files});   

    const payload = {
      guid: getGuidFromPath(),
      printFileItemId: this.props.currentItem.itemId,
      commentWindowVisible: true,
      file: files[0]
    };

    this.setState({
      fileUploading: true
    });
    const {response} = await api.uploadFile(payload);

    if (response) {
      this.setState({isUploaded: true});

      this.setState({
        fileUploading: false
      });
    }
    this.props.getPrintItem(this.props.currentItem.itemId)
  }

  onCancel() {
    this.setState({
      files: []
    });
  }

  render() {
    const {language, currentItem} = this.props;
    const {isUploaded, files, fileUploading, loaded, total} = this.state;
    const percentUpload = Math.round((loaded * 100) / total) || 0;

    return (
      <Fragment>
        <div>
          { this.state.files[0] &&
          <ProgressModal
            isUploaded={ isUploaded && !fileUploading }
            loaded={ loaded }
            total={ total }
            language={ language }
            percentUpload={ percentUpload }
            currentItemId={ currentItem.itemId }
            fileName={ files[0] && files[0].name.substring(0, 30) }/>
          }
          <div className="dropzone-wrapper dropzone-wrapper-lg">
            <Dropzone
              disabled={ isUploaded && currentItem.fileUpload.status !== 1 && currentItem.itemStatus !== 'wait_for_upload' }
              accept={ ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png', 'image/tif', 'image/tiff', '.eps'] }
              onDrop={ this.onDrop.bind(this) }
              onFileDialogCancel={ this.onCancel.bind(this) }>
              { ({getRootProps, getInputProps}) => (
                <div { ...getRootProps() }>
                  <input { ...getInputProps() } />
                  <div className={ `dropzone-content ${ isUploaded && 'is-uploaded' }` }>
                  </div>
                </div>
              ) }
            </Dropzone>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({Items, Navigation}) => ({
  printItems: Items.printItems,
  currentItem: Items.item,
  language: Items.language,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  getAllPrintItems: () => dispatch(getAllPrintItems.request()),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  setItemFile: (data) => dispatch(setItemFile(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DropZone);

