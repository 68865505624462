import { takeEvery, select } from 'redux-saga/effects';
import * as itemActions from '../actions/Items';
import api from '../utils/api';
import { apiRequest } from './index';
import { Slide, toast } from "react-toastify";
import { getTranslate } from "../utils/translate";

const getPrintOrders = apiRequest.bind(null, itemActions.getPrintOrders, api.getPrintOrders);
const getAllPrintItems = apiRequest.bind(null, itemActions.getAllPrintItems, api.getAllPrintItems);
const getPrintItem = apiRequest.bind(null, itemActions.getPrintItem, api.getPrintItem);
const updatePrintExecution = apiRequest.bind(null, itemActions.updatePrintExecution, api.updatePrintExecution);
const addFileComment = apiRequest.bind(null, itemActions.addFileComment, api.addFileComment);
const acceptRegulatoryCheckWithErrors = apiRequest.bind(null, itemActions.acceptRegulatoryCheckWithErrors, api.acceptRegulatoryCheckWithErrors);
const acceptTechCheckWithErrors = apiRequest.bind(null, itemActions.acceptTechCheckWithErrors, api.acceptTechCheckWithErrors);
const releasePrintFileItem = apiRequest.bind(null, itemActions.releasePrintFileItem, api.releasePrintFileItem);
const declineProofFile = apiRequest.bind(null, itemActions.declineProofFile, api.declineProofFile);

export function* handleUpdateSuccess() {
  const getLanguage = (state) => state.Items.language;
  const currentLanguage = yield select(getLanguage);

  yield toast(getTranslate(currentLanguage, 'updateSuccess'), {
    transition: Slide,
    closeButton: true,
    autoClose: 2000,
    position: 'bottom-center',
    type: 'success'
  })
}

export function* watchGetPrintOrders() {
  yield takeEvery(itemActions.GET_PRINT_ORDERS.REQUEST, getPrintOrders)
}

export function* watchGetAllPrintItems() {
  yield takeEvery(itemActions.GET_ALL_PRINT_ITEMS.REQUEST, getAllPrintItems)
}

export function* watchGetPrintItem() {
  yield takeEvery(itemActions.GET_PRINT_ITEM.REQUEST, getPrintItem)
}

export function* watchAddFileComment() {
  yield takeEvery(itemActions.ADD_FILE_COMMENT.REQUEST, addFileComment)
}

export function* watchAddFileCommentSuccess() {
  yield takeEvery(itemActions.ADD_FILE_COMMENT.SUCCESS, handleUpdateSuccess)
}

export function* watchAcceptRegulatoryCheckWithErrors() {
  yield takeEvery(itemActions.ACCEPT_REGULATORY_CHECK_WITH_ERRORS.REQUEST, acceptRegulatoryCheckWithErrors)
}

export function* watchAcceptTechCheckWithErrors() {
  yield takeEvery(itemActions.ACCEPT_TECH_CHECK_WITH_ERRORS.REQUEST, acceptTechCheckWithErrors)
}

export function* watchUpdatePrintExecution() {
  yield takeEvery(itemActions.UPDATE_PRINT_EXECUTION.REQUEST, updatePrintExecution);
}

export function* watchUpdatePrintExecutionSuccess() {
  yield takeEvery(itemActions.UPDATE_PRINT_EXECUTION.SUCCESS, handleUpdateSuccess);
}

export function* watchReleasePrintFileItem() {
  yield takeEvery(itemActions.RELEASE_PRINT_FILE_ITEM.REQUEST, releasePrintFileItem);
}

export function* watchDeclineProofFile() {
  yield takeEvery(itemActions.DECLINE_PROOF_FILE.REQUEST, declineProofFile);
}
