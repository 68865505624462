import React from 'react';
import ReactDOM from 'react-dom';
import ClearCache from "react-clear-cache";

import * as serviceWorker from './serviceWorker';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Main from './layout/Main';
import rootSaga from './sagas'
import 'assets/styles/index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import history from './utils/history';
import 'react-toastify/dist/ReactToastify.css';
const store = configureStore();
const rootElement = document.getElementById('root');

store.runSaga(rootSaga);

const renderApp = Component => {
  ReactDOM.render(
    <ClearCache auto>
      {({ isLatestVersion, emptyCacheStorage }) => (
        <Provider store={store}>
          <Router history={history}>
            <Component/>
          </Router>
        </Provider>
      )}
    </ClearCache>,
    rootElement
  )
};

renderApp(Main);

serviceWorker.unregister();
