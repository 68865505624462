import React from 'react';
import { getTranslate } from 'utils/translate';
import './styles.scss';

const ProgressModal = ({isUploaded, total, percentUpload, loaded, fileName, language}) => {

  return (
    <div className="progress-modal">
      <p className="progress-modal__title">
        { isUploaded ? getTranslate(language, 'fileUpload.uploaded') : getTranslate(language, 'fileUpload.uploading') }
      </p>
      <div className="progress-modal__body">
        <p className="t t--12 t--white">{ fileName }</p>
        <div className="progress-modal__progress-bar-wrapper">
          <div
            className={ `progress-modal__progress-bar ${ isUploaded && 'progress-modal__progress-bar--uploaded' }` }
            style={ {width: `${ percentUpload }%`} }
          >
          </div>
        </div>
        <p className="t t--10 t--white">{ `${ loaded } MB of ${ total } MB` }</p>
      </div>
    </div>
  )
};

export default ProgressModal;
