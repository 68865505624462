import React from 'react';
import { MetroSpinner } from "react-spinners-kit";
import { get } from 'lodash';

import { getTranslate } from "utils/translate";

import '../styles.scss';

const PrintItem = ({ item, history, currentTab, setNavigationTab, editedMode, toggleWarningModal, getPrintItem, currentItem, language }) => {
  if (!item) return;
  const getLabel = (status) => {
    switch (status) {
      case 'canceled':
        return (
          <button onClick={getTab} className={'label label--disabled'}>
            {getTranslate(language, 'printList.canceled')}
          </button>
        )
      case 'running':
        return (
          <button onClick={getTab} className={'label label--active'}>
            {getTranslate(language, 'printList.running')}
          </button>
        )
      case 'released':
        return (
          <button onClick={getTab} className={'label label--done'}>
            {getTranslate(language, 'printList.released')}
          </button>
        )
      default:
        return null;
    }
  };

  const getItem = () => {
    if (editedMode) {
      toggleWarningModal({
        warningModalOpen: true,
        nextTab: currentTab,
        nextItem: item.itemId,
      });
      return null
    }

    window.scrollTo({
      top: 100,
      behavior: "smooth"
    });
    history.push(`/${item.guid}`)
  };

  const getTab = (e) => {
    e.stopPropagation();
    if (editedMode) {
      toggleWarningModal({
        warningModalOpen: true,
        nextTab: e.target.name
      })
    }
    const name = e.target.name;
    getItem();
    if (name) {
      setTimeout(() => setNavigationTab(name), 200)
    }
  };

  return (
    <div
      className={`print-item ${currentItem && currentItem.itemId === item.itemId && 'is-active'}`}
      onClick={getItem}>
      <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: 2 }}>
        <MetroSpinner size={40} color="#fff" loading={item.itemStatus === 'processing'}/>
      </div>
      <div className="print-item__text">
        <p className="t t--16 t--bold t--uppercase">
          {getTranslate(language, 'printList.order')}{': '}
          {get(item, 'salesId', 'n/a')} |{' '}
          {getTranslate(language, 'printList.itemNumber')}{': '}
          {get(item, 'itemId', 'n/a')}
        </p>
        <p className="t t--16">{item.itemName}</p>
        <p className="t t--16">{get(item, 'infoPrintProzess', '')}</p>
      </div>
      <div className="labels">
        <span className='t t--16 t--uppercase'>status:</span>
        {getLabel(item.status)}{' '}
        {get(item, 'statusDate', 'am 23. Mai 2019')}
      </div>
    </div>
  )
};

export default PrintItem;
