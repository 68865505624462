import React from 'react';
import canceled from 'assets/images/canceled.svg';
import done from 'assets/images/done.svg';
import './styles.scss';

const StatusBlock = ({title, text, statusType}) => {
  return (
    <div className="status-block">
      <p className="content-block__title content-block__title--m-b">{title}</p>
      <p className="t t-16">{text}</p>
      <img alt={statusType} className="status-block__image" src={statusType === 'canceled' ? canceled : done}/>
    </div>
  )
};

export default StatusBlock;
