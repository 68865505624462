import React, { Component } from 'react';
import { connect } from "react-redux";
import { MetroSpinner } from "react-spinners-kit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons/faThumbsDown";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons/faSearchPlus";

import { declineProofFile, getPrintItem, releasePrintFileItem, setEditedMode } from "actions/Items";
import { openFile } from "utils/openFile";
import { getTranslate, getTranslateDyn } from "utils/translate";
import api from "utils/api";
import { getGuidFromPath } from "utils/helpers";
import { MAX_CHARS_REMARK } from 'utils/constants';

import InfoModal from "../InfoModal";
import WarningModal from "../WarningModal";
import RemarkField from 'components/RemarkField';

class Approval extends Component {
  state = {
    checked: false,
    fileLoading: false,
    edited: false,
    releaseComment: "",
    commentFieldOpen: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentItem.itemId !== this.props.currentItem.itemId) {
      this.setState({
        checked: false,
        commentFieldOpen: false,
        releaseComment: ""
      })
    }
  }

  getFile = async () => {
    const payload = {
      printFileItemId: this.props.currentItem.itemId,
      guid: getGuidFromPath(),
      regulatoryFile: false,
      "specificationFile": false, "printFile": false, "proofFile": true
    };

    this.setState({
      fileLoading: true
    });

    const {response} = await api.downloadFile(payload);
    const file = new Blob([ response ], {
      type: "application/pdf",
    });
    this.setState({
      fileLoading: false,
      checked: true
    });

    openFile(file)
  };

  handleFileRelease = () => {
    const payload = {
      printFileItemId: this.props.currentItem.itemId,
      guid: getGuidFromPath(),
      message: this.state.releaseComment
    };

    this.props.releasePrintFileItem(payload);
  };

  toggleCommentField = () => {
    this.setState({
      commentFieldOpen: true
    })
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });

    //to toggle warning modal before switching tab
    if (!this.state.edited) {
      this.props.setEditedMode(true);
      this.setState({
        edited: true
      })
    }
  };

  declineProofFile = () => {
    if(!this.state.releaseComment || this.state.releaseComment.length === 0) {
      this.toggleInfoModal();
    } 
    else {
      const {currentItem} = this.props;
      const payload = {
        guid: getGuidFromPath(),
        printFileItemId: this.props.currentItem.itemId,
        message: this.state.releaseComment
      };
      this.props.setEditedMode(false);
  
      this.props.declineProofFile(payload);
      this.props.getPrintItem(currentItem.itemId)
    }
  };

  goBackToRelease = () => {
    this.setState({
      commentFieldOpen: false
    })
  };

  renderPreviewPdf = () => {
    const {currentItem, language} = this.props;
    const isChecked = currentItem.releaseCheck.checked || this.state.checked;
    return (
      <div>
        <p className="content-block__title content-block__title--m-b">
          { getTranslate(language, 'printApproval.title') }
        </p>
        <p className="t">
          { getTranslate(language, 'printApproval.text') }
        </p>
        <button
          onClick={ this.getFile }
          className={ `button ${isChecked ? "button--light" : "button--orange" }` }>
          <FontAwesomeIcon icon={ faSearchPlus } style={ {marginRight: 4} }/>
          { getTranslate(language, 'printApproval.previewPdf') }
          <div className="info-block__spinner info-block__spinner--absolute">
            <MetroSpinner size={ 25 } color="#AAC1C4" loading={ this.state.fileLoading }/>
          </div>
        </button>
        {currentItem?.releaseCheck?.infoEK2Customer && (
          <div style={{ marginTop: '20px' }}>
            <label className='content-block__info-scrollable-text-aria-label'>
              {getTranslate(language, 'printApproval.additionalInfoProofSheet')}
            </label>
            <textarea disabled className='content-block__info-scrollable-text-aria '>
              {currentItem.releaseCheck.infoEK2Customer}
            </textarea>
          </div>
        )}
        { isChecked && (
          <div className="button-group">
            <button className="button button--white" onClick={ this.declineProofFile }>
              { getTranslate(language, 'printApproval.noRelease') }
              <FontAwesomeIcon icon={ faThumbsDown } style={ {marginLeft: 4} }/>
            </button>
            <button className="button" onClick={ this.handleFileRelease }>
              { getTranslate(language, 'printApproval.release') }
              <FontAwesomeIcon icon={ faThumbsUp } style={ {marginLeft: 4} }/>
            </button>
          </div>
        ) }
        { isChecked && (
          <div className="content-block__textarea-wrapper">
            <p className="t t--12" style={ {marginLeft: 20} }>
              { getTranslate(language, 'printApproval.message') } { ' ' }
              { getTranslateDyn(language, 'remarkField.maxCharacters', ['$NUMBER$'], [String(MAX_CHARS_REMARK)]) }</p>              
            <RemarkField
              className="content-block__textarea"
              name="releaseComment"
              maxLength={MAX_CHARS_REMARK}
              onChange={ this.onChange } />              
          </div>
        ) }
      </div>
    )
  };

  renderSuccessStatus = () => {
    const {language, currentItem} = this.props;

    return (
      <div>
        <p className="content-block__title content-block__title--m-b">
          { getTranslate(language, 'printApproval.successTitle') }
        </p>
        <p className="t--min-height">
          { getTranslate(language, 'printApproval.successText') }
        </p>
        <button
          onClick={ this.getFile }
          className={ `button ${ currentItem.releaseCheck.checked ? "button--light" : "button--orange" }` }>
          <FontAwesomeIcon icon={ faSearchPlus } style={ {marginRight: 4} }/>
          { getTranslate(language, 'printApproval.previewPdf') }
          <div className="info-block__spinner info-block__spinner--absolute">
            <MetroSpinner size={ 25 } color="#AAC1C4" loading={ this.state.fileLoading }/>
          </div>
        </button>
      </div>
    )
  };

  toggleInfoModal = () => {
    this.setState({
      infoModalOpen: !this.state.infoModalOpen
    })
  };

  renderInfoModal = () => (
      <InfoModal
        language={ this.props.language }
        title={ getTranslate(this.props.language, 'printApproval.modalTitle') }
        text={ getTranslate(this.props.language, 'printApproval.modalText') }
        toggleAction={ this.toggleInfoModal }
      />
    );

  render() {
    const {warningModalOpen, currentItem} = this.props;
    const {infoModalOpen} = this.state;
    const isReleased = currentItem.itemStatus === 'released';

    return (
      <div>
        { warningModalOpen && <WarningModal/> }
        { infoModalOpen && this.renderInfoModal()}
        { !isReleased && <button className="content-block__info" onClick={ this.toggleInfoModal }/> }
        { !isReleased && this.renderPreviewPdf() }
        { isReleased && this.renderSuccessStatus() }
      </div>
    )
  }
};

const mapStateToProps = ({Items, Navigation}) => ({
  language: Items.language,
  printItems: Items.printItems,
  currentItem: Items.item,
  warningModalOpen: Items.warningModalOpen,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  declineProofFile: (data) => dispatch(declineProofFile.request(data)),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  setEditedMode: (data) => dispatch(setEditedMode(data)),
  releasePrintFileItem: (data) => dispatch(releasePrintFileItem.request(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Approval);

