import React from 'react';
import main from 'assets/images/main-img.svg';
import mainEn from 'assets/images/main-img-en.svg';
import mainOrdersEn from 'assets/images/filter-page-main-en.svg';
import mainOrders from 'assets/images/filter-page-main.svg';
import './styles.scss';

const InitialBlock = ({language, history}) => {
  const getImage = () => {
    const ordersPage = history.location.pathname.includes('orders');
    if (ordersPage) return language === 'en' ? mainOrdersEn :  mainOrders
    return language === 'en' ? mainEn :  main
  };

  return (
    <div>
      <img src={getImage()} alt="" className="full-image"/>
    </div>
  )
};

export default InitialBlock;
