import axios from 'axios';
import {baseUrl, token} from "../config/environment";

const getPrintOrders = (data) => {
  return axios.get(`${baseUrl}/v1/axintegration/ccp/orders/${data.customerGuid}/${data.filter}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const getAllPrintItems = (itemGuid) => {
  return axios.get(`${baseUrl}/v1/axintegration/ccp/items/${itemGuid}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const getPrintItem = (id) => {
  const guid = window.location.pathname.split('/').pop();

  return axios.get(`${baseUrl}/v1/axintegration/ccp/item/${guid}/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const downloadFile = (data) => {
  return axios.post(`${baseUrl}/v1/axintegration/ccp/download`, data, {
    responseType: 'blob',
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const downloadInfoFile = (languageId) => {
  return axios.get(`${baseUrl}/v1/axintegration/ccp/download_info/${languageId}`, {
    responseType: 'blob',
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const uploadFile = (data) => {
  const guid = window.location.pathname.split('/').pop();

  const payload = new FormData();
  payload.append('upload-file', data.file);

  return axios.post(`${baseUrl}/v1/axintegration/ccp/upload/printfile/${guid}/${data.printFileItemId}`, payload, {
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const updatePrintExecution = (data) => {
  return axios.post(`${baseUrl}/v1/axintegration/ccp/printspecification`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const addFileComment = (data) => {
  return axios.post(`${baseUrl}/v1/axintegration/ccp/customercomment`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const acceptRegulatoryCheckWithErrors = (data) => {
  const guid = window.location.pathname.split('/').pop();

  return axios.post(`${baseUrl}/v1/axintegration/ccp/accept/regulatoryfailure/${guid}/${data.printFileItemId}`, {},{
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const acceptTechCheckWithErrors = (data) => {
  const guid = window.location.pathname.split('/').pop();

  return axios.post(`${baseUrl}/v1/axintegration/ccp/accept/technicalfailure/${guid}/${data.printFileItemId}`, {},{
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const declineProofFile = (data) => {
  return axios.post(`${baseUrl}/v1/axintegration/ccp/decline`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};

const releasePrintFileItem = (data) => {
  return axios.post(`${baseUrl}/v1/axintegration/ccp/release`, data, {
    headers: {
      "Authorization": `Bearer ${token}`
    }})
    .then(response => {
      return ({response: response.data})
    })
    .catch(err => ({error: err.response.data}));
};


export default {
  getPrintOrders,
  getAllPrintItems,
  downloadFile,
  downloadInfoFile,
  uploadFile,
  addFileComment,
  acceptTechCheckWithErrors,
  updatePrintExecution,
  getPrintItem,
  releasePrintFileItem,
  declineProofFile,
  acceptRegulatoryCheckWithErrors
}
