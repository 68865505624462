import intl from '../intl';
import get from 'lodash/get';
import { replace } from 'lodash';

export const getTranslate = (language, key) => {
  const strings = intl.strings[language];
  const translation = get(strings, key);

  return translation
};

export const getTranslateDyn = (language, key, placeholders, values) => {

  let translation = getTranslate(language, key);

  for (let index = 0; index < placeholders.length; index++) {
    translation = replace(translation, placeholders[index], values[index]); 
  }
  
  return translation;
}
