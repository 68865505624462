import React, { Component } from 'react';
import { MetroSpinner } from "react-spinners-kit";
import { connect } from "react-redux";

import { acceptRegulatoryCheckWithErrors, getAllPrintItems, getPrintItem } from "actions/Items";
import { setNavigationTab } from "actions/Navigation";
import api from "utils/api";
import { openFile } from "utils/openFile";
import { getGuidFromPath } from "utils/helpers";
import { getTranslate } from "utils/translate";

import Tooltip from "components/Tooltip";
import InfoModal from "../InfoModal";

class LegalCheck extends Component {
  state = {
    tooltipOpen: false,
    fileLoading: false,
    checked: false,
    infoModalOpen: false
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  };

  acceptCheckWithErrors = () => {
    const {currentItem} = this.props;

    const payload = {
      guid: getGuidFromPath(),
      printFileItemId: currentItem.itemId
    };

    this.props.acceptRegulatoryCheckWithErrors(payload);
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });

    this.props.getPrintItem(currentItem.itemId);
  };

  getFile = async () => {
    const payload = {
      printFileItemId: this.props.currentItem.itemId,
      guid: getGuidFromPath(),
      regulatoryFile: true,
      "specificationFile": false, "printFile": false, "proofFile": false
    };

    this.setState({
      fileLoading: true
    });

    const {response} = await api.downloadFile(payload);
    const file = new Blob([ response ], {
      type: "application/pdf",
    });
    this.setState({
      fileLoading: false,
      checked: true
    });
    openFile(file)
  };

  renderProgressStatus = () => {
    const {language} = this.props;
    return (
      <div>
        <div className="progress-block">
          <p className="progress-block__header">
            { getTranslate(language, 'legalCheck.title') }
          </p>
          <div className="progress-block__body">
            <p className="progress-block__text">
              { getTranslate(language, 'legalCheck.progressText') }
            </p>
          </div>
        </div>
      </div>
    )
  };

  renderSuccessStatus = () => {
    const {language} = this.props;
    return (
      <div>
        <p className="content-block__title content-block__title--m-b">
          { getTranslate(language, 'legalCheck.title') }
        </p>
        <p className="t t--min-height">
          { getTranslate(language, 'legalCheck.success') }
        </p>
        <div className="sign sign--success" />
        <button
          onClick={ this.getFile }
          className="button button--white">
          { getTranslate(language, 'legalCheck.openReport') }
          <div className="info-block__spinner info-block__spinner--absolute" >
            <MetroSpinner size={ 25 } color="#AAC1C4" loading={ this.state.fileLoading }/>
          </div>
        </button>
      </div>
    )
  };

  goToFileUpload = () => {
    this.props.setNavigationTab('fileUpload')
  };

  renderErrorsStatus = () => {
    const {tooltipOpen, fileLoading} = this.state;
    const {currentItem, language} = this.props;
    const isChecked = currentItem.regulatoryCheck.checked || this.state.checked;

    return (
      <div>
        <p className="content-block__title content-block-title__error">
          { getTranslate(language, 'legalCheck.title') }
        </p>
        <p className="t">
          { getTranslate(language, 'legalCheck.errorText') }
        </p>
        <div className="sign sign--error" />
        <button
          onClick={ this.getFile }
          className={ `button button--white ${ isChecked && 'button--light' }` }>
          { getTranslate(language, 'legalCheck.openReport') }
          <div className="info-block__spinner info-block__spinner--absolute">
            <MetroSpinner size={ 25 } color="#AAC1C4" loading={ fileLoading }/>
          </div>
        </button>
        {
          isChecked && (
            <div className="button-group">
              <button
                className="button button--white button--error"
                onClick={ this.toggleTooltip }>
                { getTranslate(language, 'legalCheck.continueWithErrors') }
              </button>
              { tooltipOpen &&
              <Tooltip
                type="warning"
                language={ language }
                cancelAction={ this.toggleTooltip }
                acceptAction={ this.acceptCheckWithErrors }
                text={ getTranslate(language, 'errorTooltip.text') }
              /> }
              <button
                className="button"
                onClick={ this.goToFileUpload }>
                { getTranslate(language, 'legalCheck.uploadNewFile') }
              </button>
            </div>
          )
        }
      </div>
    )
  };

  renderErrorsInfo = () => {
    const {language, currentItem} = this.props;
    const isChecked = currentItem.regulatoryCheck.checked || this.state.checked;

    return (
      <div>
        <p className="content-block__title content-block-title__error">
          { getTranslate(language, 'legalCheck.title') }
        </p>
        <p className="t">
          { getTranslate(language, 'legalCheck.errorText') }
        </p>
        <div className="sign sign--error"/>
        <button
          onClick={ this.getFile }
          className={ `button button--white ${ isChecked && 'button--light' }` }>
          { getTranslate(language, 'legalCheck.openReport') }
          <div className="info-block__spinner info-block__spinner--absolute">
            <MetroSpinner size={ 25 } color="#AAC1C4" loading={ this.state.fileLoading }/>
          </div>
        </button>
      </div>
    )
  };

  renderNoLegalCheck = () => {
    const {language} = this.props;
    return (
      <div>
        <p className="content-block__title content-block-title__error">
          { getTranslate(language, 'legalCheck.title') }
        </p>
        <p className="t" style={ {minHeight: 115} }>
          { getTranslate(language, 'legalCheck.withoutCheck') }
        </p>
        <div className="sign sign--forbidden" />
      </div>
    )
  };

  toggleInfoModal = () => {
    this.setState({
      infoModalOpen: !this.state.infoModalOpen
    })
  };

  render() {
    const {currentItem, printItems: {clerk}, language} = this.props;
    const regulatoryCheckStatus = currentItem.regulatoryCheck.status;
    const withoutCheck = currentItem.regulatoryCheck.noLegalCheck;
    const withErrors = currentItem.regulatoryCheck.withFailures;

    return (
      <div>
        <button className="content-block__info" onClick={ this.toggleInfoModal }/>
        { this.state.infoModalOpen && (
          <InfoModal
            language={ language }
            title={ getTranslate(language, 'legalCheck.modalTitle') }
            text={ getTranslate(language, 'legalCheck.modalText') }
            toggleAction={ this.toggleInfoModal }
          />
        ) }
        { regulatoryCheckStatus === 0 && this.renderProgressStatus() }
        { withoutCheck && this.renderNoLegalCheck() }
        { !withoutCheck && regulatoryCheckStatus === 2 && !withErrors && this.renderSuccessStatus() }
        { regulatoryCheckStatus === 1 && withErrors && this.renderErrorsStatus() }
        { regulatoryCheckStatus === 2 && withErrors && this.renderErrorsInfo() }
        <p className="content-block__contact">
          { `${ clerk.firstName } ${ clerk.lastName }, ${ clerk.phone }, ${ clerk.mail }` }
        </p>
      </div>
    )
  }
}

const mapStateToProps = ({Items, Navigation}) => ({
  language: Items.language,
  printItems: Items.printItems,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  getAllPrintItems: () => dispatch(getAllPrintItems.request()),
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
  acceptRegulatoryCheckWithErrors: (data) => dispatch(acceptRegulatoryCheckWithErrors.request(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LegalCheck);
