import React, { Component } from 'react';
import { connect } from "react-redux";

import { getPrintItem, getAllPrintItems, updatePrintExecution, toggleWarningModal, setEditedMode } from "actions/Items";
import { setNavigationTab } from "actions/Navigation";
import { getGuidFromPath } from "utils/helpers";
import { getTranslate, getTranslateDyn } from "utils/translate";
import { MAX_CHARS_REMARK } from 'utils/constants';
import './styles.scss';

import WarningModal from "../WarningModal";
import InfoModal from "../InfoModal";
import RemarkField from 'components/RemarkField';

class PrintSpecs extends Component {
  state = {
    commentsWindowVisible: false,
    printExecutionComment: '',
    infoModalOpen: false,
    edited: false
  };

  componentDidMount () {
    if (this.props.currentItem) {
      const { currentItem } = this.props;
      this.setState({
        isHintVisible: currentItem.printSpecification.isRestart,
        printExecutions: currentItem.printSpecification.printExecutions,
        printExecutionComment: currentItem.printSpecification && currentItem.printSpecification.printExecutionComment,
        printRefinments: currentItem.printSpecification.printRefinments,
        printExecutionsCost: currentItem.printSpecification.printExecutionsCost,
      }, () => {
        this.checkComment();
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.currentItem !== this.props.currentItem) {
      const { currentItem } = this.props;

      this.checkComment();
      this.setState({
        isHintVisible: currentItem.printSpecification.isRestart,
        printExecutionComment: currentItem.printSpecification.printExecutionComment,
        printExecutions: currentItem.printSpecification.printExecutions,
        printRefinments: currentItem.printSpecification.printRefinments,
        printExecutionsCost: currentItem.printSpecification.printExecutionsCost,
      })
    }
  };

  checkComment = () => {
    const arr = [ ...this.state.printExecutionsCost, ...this.state.printExecutions ];
    const isCommentAllowed = arr.find(el => {
      return el.selected && el.commentsAllowed
    });

    this.setState({
      commentsWindowVisible: !!isCommentAllowed
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });

    //to toggle warning modal before switching tab
    if (!this.state.edited) {
      this.props.setEditedMode(true);
      this.setState({
        edited: true
      })
    }
  };

  handleOptionSelect = (el, index, specType) => {
    const { currentItem } = this.props;
    if (currentItem.printSpecification.status === 2 && currentItem.itemStatus !== 'wait_for_upload') return null;

    const copiedState = [ ...this.state[specType] ];
    if (specType === 'printExecutions' || specType === 'printRefinments') {
      copiedState.forEach((el) => {
        el.selected = false;
      })
    }
    copiedState[index].selected = !copiedState[index].selected;

    this.setState({ [specType]: copiedState },
      () => this.checkComment()
    );

    //to toggle warning modal before switching tab
    if (!this.state.edited) {
      this.props.setEditedMode(true);
      this.setState({
        edited: true
      })
    }
  };

  renderPrintExecutions = () => {
    const { printExecutions } = this.state;
    return printExecutions.map((el, index) => (
      <div className="checkbox" key={ el.id }>
        <span
          onClick={ () => {
            this.handleOptionSelect(el, index, 'printExecutions')
          } }
          className={ `checkbox__element ${ el.selected && 'is-selected' }` }/>
        <label className="checkbox__label">
          <strong>{ el.label }</strong>
          { el.description ? `: ${ el.description }` : '' }
        </label>
      </div>
    ))
  };

  renderPrintRefinments = () => {
    const { language } = this.props;
    const { printRefinments } = this.state;
    if (!printRefinments.length) return null;
    return (
      <div style={ { marginTop: 30 } }>
        <p className="t t--uppercase">
          { getTranslate(language, 'printExecution.protectiveVarnish') }
        </p>
        { printRefinments.map((el, index) => (
          <div className="checkbox" key={ el.id }>
            <span
              onClick={ () => {
                this.handleOptionSelect(el, index, 'printRefinments')
              } }
              className={ `checkbox__element ${ el.selected && 'is-selected' }` }/>
            <label className="checkbox__label">
              { el.description }
            </label>
          </div>))
        }
      </div>
    )
  };

  renderPrintExecutionsCost = () => {
    const { language } = this.props;
    const { printExecutionsCost } = this.state;

    if (!printExecutionsCost.length) return null;

    return (
      <div style={ { marginTop: 30 } }>
        <p className="t t--uppercase">
          { getTranslate(language, 'printExecution.chargedRefining') }
        </p>
        { printExecutionsCost.map((el, index) => (
          <div className="checkbox" key={ el.id }>
            <span
              onClick={ () => {
                this.handleOptionSelect(el, index, 'printExecutionsCost')
              } }
              className={ `checkbox__element ${ el.selected && 'is-selected' }` }/>
            <label className="checkbox__label">
              <strong>{ el.label }</strong>:{ ' ' }
              { el.description }
            </label>
          </div>))
        }
      </div>
    )
  };

  toggleModal = () => {
    this.setState({
      isHintVisible: !this.state.isHintVisible
    });
  };

  // renderHint = () => {
  //   const { language } = this.props;
  //   return (
  //     <div className="warning-modal warning-modal--print-spec">
  //       <p className="warning-modal__title">
  //         { getTranslate(language, 'printExecution.restartHintTitle') }
  //       </p>
  //       <p className="t--14 t--white">
  //       </p>
  //     </div>
  //   )
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    const { printExecutionComment, printExecutions, printExecutionsCost, printRefinments } = this.state;
    const arr = [ ...printExecutions, ...printExecutionsCost, ...printRefinments ];
    let selectedIds = [];

    arr.forEach((el) => {
      if (el.selected) selectedIds.push(el.id)
    });

    const payload = {
      guid: getGuidFromPath(),
      printFileItemId: this.props.currentItem.itemId,
      selectedIds: selectedIds,
      printExecutionComment: printExecutionComment
    };

    this.props.updatePrintExecution(payload);
    this.props.setEditedMode(false);
    // if (currentItem.itemStatus === 'wait_for_upload') {
    //   this.props.setNavigationTab('fileUpload')
    // }
  };

  toggleInfoModal = () => {
    this.setState({
      infoModalOpen: !this.state.infoModalOpen
    })
  };

  render () {
    const { language, currentItem, warningModalOpen } = this.props;
    const {
      commentsWindowVisible,
      isHintVisible,
      printExecutionComment,
      printRefinments,
      printExecutions,
      printExecutionsCost
    } = this.state;
    return (
      <div className="content-block--print-spec">
        <button className="content-block__info" onClick={ this.toggleInfoModal }/>
        { warningModalOpen && <WarningModal/> }
        { this.state.infoModalOpen && (
          <InfoModal
            language={ language }
            title={ getTranslate(language, 'printExecution.modalTitle') }
            text={ getTranslate(language, 'printExecution.modalText') }
            toggleAction={ this.toggleInfoModal }
          />
        ) }
        <p className="content-block__title">{ getTranslate(language, 'printExecution.title') }</p>
        <form action="">
          { printExecutions && this.renderPrintExecutions() }
          { printRefinments && this.renderPrintRefinments() }
          { printExecutionsCost && this.renderPrintExecutionsCost() }
          <div>
            { (commentsWindowVisible) && (
              <div className="content-block__textarea-wrapper">
                <p className="t t--12"
                   style={ { marginLeft: 20 } }>
                  <span className="t--uppercase">{ getTranslate(language, 'printExecution.message') }</span>&nbsp;
                  { getTranslateDyn(language, 'remarkField.maxCharacters', [ '$NUMBER$' ], [ String(MAX_CHARS_REMARK) ]) }
                </p>
                <RemarkField
                  className="content-block__textarea"
                  value={ printExecutionComment }
                  disabled={
                    currentItem.itemStatus !== 'wait_for_upload' &&
                    currentItem.printSpecification.status === 2
                  }
                  name="printExecutionComment"
                  maxLength={ MAX_CHARS_REMARK }
                  onChange={ this.onChange }/>
              </div>
            ) }
            { (currentItem.itemStatus === 'wait_for_upload' || currentItem.printSpecification.status) !== 2 && (
              <button
                style={currentItem?.printSpecification.isRestart ? { bottom: 70 } : {}}
                onClick={ this.handleSubmit }
                className="button button--bottom button--round">
                { getTranslate(language, 'printExecution.confirm') }
              </button>
            ) }
            { currentItem?.printSpecification.isRestart && !this.state.edited && (
              <p className="content-block__text-hint">
                { getTranslate(language, 'printExecution.restartHint') }
              </p>
            ) }
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ Items }) => ({
  language: Items.language,
  warningModalOpen: Items.warningModalOpen,
  currentItem: Items.item
});

const mapDispatchToProps = dispatch => ({
  updatePrintExecution: (data) => dispatch(updatePrintExecution.request(data)),
  setEditedMode: (data) => dispatch(setEditedMode(data)),
  toggleWarningModal: (data) => dispatch(toggleWarningModal(data)),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
  getAllPrintItems: () => dispatch(getAllPrintItems.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintSpecs);
