import React from 'react';
import { getTranslate } from "utils/translate";
import './styles.scss';

const InfoModal = ({title, text, toggleAction, language}) => {
  return (
    <div className="info-modal">
      <p className="info-modal__header">{ title }</p>
      <p className="info-modal__text">{ text }</p>
      <button className="button button--round button--bottom" onClick={ toggleAction }>
        { getTranslate(language, 'close') }
      </button>
    </div>
  )
};

export default InfoModal;
