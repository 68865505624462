import React, {Component} from 'react';
import { connect } from "react-redux";

import { getTranslate } from "utils/translate";
import { setNavigationTab } from "actions/Navigation";
import {
  getPrintItem,
  setEditedMode,
  toggleWarningModal
} from "actions/Items";
import './styles.scss';

class WarningModal extends Component{
  toggleModal = () => {
    this.props.toggleWarningModal(false);
  };

  goToNextTab = () => {
    this.props.getPrintItem(this.props.nextItem);
    this.props.toggleWarningModal(false);
    this.props.setEditedMode(false);

    setTimeout(() => {
      this.props.setNavigationTab(this.props.nextTab);
    }, 200)
  };

  render() {
    const {language} = this.props;

    return (
      <div className="warning-modal">
        <p className="warning-modal__title">
          {getTranslate(language, 'warningModal.title')}
        </p>
        <p className="t--14 t--white">
          {getTranslate(language, 'warningModal.text')}
        </p>
        <div className="button-group">
          <button onClick={this.toggleModal} className="button button--modal-white">
            {getTranslate(language, 'warningModal.cancel')}
          </button>
          <button onClick={this.goToNextTab} className="button button--modal-orange">
            {getTranslate(language, 'warningModal.submit')}
          </button>
        </div>
      </div>
    )
  }
};

const mapStateToProps = ({Items}) => ({
  language: Items.language,
  nextTab: Items.nextTab,
  nextItem: Items.nextItem
});

const mapDispatchToProps = dispatch => ({
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  setEditedMode: (data) => dispatch(setEditedMode(data)),
  toggleWarningModal: (data) => dispatch(toggleWarningModal(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningModal);
