import * as ActionTypes from 'actions/Navigation';
import * as ItemsActionTypes from 'actions/Items';

const initialState = {
  currentNavigationTab: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case (ItemsActionTypes.UPDATE_PRINT_EXECUTION.SUCCESS):
      if (action.payload.fileUpload.status === 1) {
        return {...state, currentNavigationTab: 'fileUpload'}
      }
    // case (ItemsActionTypes.ACCEPT_REGULATORY_CHECK_WITH_ERRORS.SUCCESS):
    //   return {
    //     ...state ,currentNavigationTab: 'technicalCheck'};
    // case (ItemsActionTypes.DECLINE_PROOF_FILE.SUCCESS):
    //   return { ...state, currentNavigationTab: 'technicalCheck' };
    // case (ItemsActionTypes.ACCEPT_TECH_CHECK_WITH_ERRORS.SUCCESS):
    //   return { ...state, currentNavigationTab: 'releaseCheck' };
    case (ItemsActionTypes.GET_PRINT_ITEM.SUCCESS):
      switch (action.payload.itemStatus) {
        case 'wait_for_upload':
          // if (action.payload.fileUpload.status === 2) {
          //   return {...state, currentNavigationTab: 'printSpecification'};
          // }
          return {...state, currentNavigationTab: 'fileUpload'};
        case 'ra_check':
          return {...state, currentNavigationTab: 'regulatoryCheck'};
        case 'technical_check':
          return {...state, currentNavigationTab: 'technicalCheck'};
        case 'wait_for_release':
          return {...state, currentNavigationTab: 'releaseCheck'};
        default:
          return state;
      }
    case (ActionTypes.SET_NAVIGATION_TAB):
      return {...state, currentNavigationTab: action.payload};
    default:
      return state;
  }
}
