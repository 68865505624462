import React, { Component } from 'react';
import { connect } from "react-redux";

import { acceptTechCheckWithErrors, getAllPrintItems } from "actions/Items";
import { setNavigationTab } from "actions/Navigation";
import { getTranslate } from "utils/translate";
import { getGuidFromPath } from "utils/helpers";

import Tooltip from "components/Tooltip";
import InfoModal from "../InfoModal";

class TechCheck extends Component {
  state = {
    tooltipOpen: false,
    infoModalOpen: false
  };

  renderProgressStatus = () => {
    const {language} = this.props;
    return (
      <div>
        <div className="progress-block">
          <p className="progress-block__header">
            { getTranslate(language, 'technicalCheck.title') }
          </p>
          <div className="progress-block__body">
            <p className="progress-block__text">
              { getTranslate(language, 'technicalCheck.progressText') }
            </p>
          </div>
        </div>
      </div>
    )
  };

  renderSuccessStatus = () => {
    const {language} = this.props;
    return (
      <div>
        <p className="content-block__title content-block__title--m-b">
          { getTranslate(language, 'technicalCheck.title') }
        </p>
        <p className="t t--min-height">
          { getTranslate(language, 'technicalCheck.success') }
        </p>
        <div className="sign sign--success" />
      </div>
    )
  };

  goToFileUpload = () => {
    this.props.setNavigationTab('fileUpload')
  };

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  };

  renderErrorsStatus = () => {
    const {language, currentItem} = this.props;
    return (
      <div>
        <p className="content-block__title content-block-title__error">
          { getTranslate(language, 'technicalCheck.title') }
        </p>
        <p className="t">
          { getTranslate(language, 'technicalCheck.errorText') }
        </p>
        <p className="content-block__scrollable-text">
          { currentItem.technicalCheck.failureReport }
        </p>
        <div className="button-group">
          {/* <button className="button button--white button--error" onClick={ this.toggleTooltip }>
            { getTranslate(language, 'technicalCheck.continueWithErrors') }
          </button> */}
          <button className="button" onClick={ this.goToFileUpload }>
            { getTranslate(language, 'technicalCheck.uploadNewFile') }
          </button>
          { this.state.tooltipOpen &&
          <Tooltip
            type="warning"
            language={ language }
            cancelAction={ this.toggleTooltip }
            acceptAction={ this.acceptCheckWithErrors }
            text={ getTranslate(language, 'errorTooltip.text') }
          /> }
        </div>
      </div>
    )
  };

  acceptCheckWithErrors = () => {
    const payload = {
      guid: getGuidFromPath(),
      printFileItemId: this.props.currentItem.itemId
    };

    this.props.acceptTechCheckWithErrors(payload);

    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });

    this.props.getPrintItem(this.props.currentItem.itemId);
  };

  toggleInfoModal = () => {
    this.setState({
      infoModalOpen: !this.state.infoModalOpen
    })
  };

  render() {
    const {currentItem, printItems: {clerk}, language} = this.props;
    const techCheckStatus = currentItem.technicalCheck.status;

    return (
      <div>
        <button className="content-block__info" onClick={ this.toggleInfoModal }/>
        { this.state.infoModalOpen && (
          <InfoModal
            language={ language }
            title={ getTranslate(language, 'technicalCheck.modalTitle') }
            text={ getTranslate(language, 'technicalCheck.modalText') }
            toggleAction={ this.toggleInfoModal }
          />
        ) }
        { techCheckStatus === 2 && this.renderSuccessStatus() }
        { techCheckStatus === 1 && this.renderErrorsStatus() }
        { techCheckStatus === 0 && this.renderProgressStatus() }
        <p className="content-block__contact">
          { `${ clerk.firstName } ${ clerk.lastName }, ${ clerk.phone }, ${ clerk.mail }` }
        </p>
      </div>
    )
  }
};

const mapStateToProps = ({Items, Navigation}) => ({
  language: Items.language,
  printItems: Items.printItems,
  currentItem: Items.item,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
  getAllPrintItems: () => dispatch(getAllPrintItems.request()),
  acceptTechCheckWithErrors: (id) => dispatch(acceptTechCheckWithErrors.request(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TechCheck);
