import React, { Component } from 'react';
import { connect } from "react-redux";

import { getAllPrintItems, getPrintItem, getPrintOrders, setEditedMode, toggleWarningModal } from "actions/Items";
import { setNavigationTab } from "actions/Navigation";
import { getTranslate } from "utils/translate";
import './styles.scss';

import PrintItem from "./PrintItem";
import PrintFlow from "components/PrintFlow";

class OrdersList extends Component {
  state = {
    firstLoad: false,
    filterParam: 'any',
    printOrders: [],
    search: ''
  };

  filteredOrders = [];

  componentDidMount() {

    this.props.getPrintOrders({
      customerGuid: window.location.pathname.split('/')[2],
      filter: 'any'
    })

  }

  componentDidUpdate(prevProps) {
    if (prevProps.printOrders !== this.props.printOrders) {
      this.setState({
        printOrders: this.props.printOrders.orders
      })

      this.filteredOrders = this.props.printOrders.orders;
    }
  }

  filterItems = (filterParam) => () => {
    if (filterParam === this.state.filterParam) {
      this.setState({
        filterParam: 'any'
      });
      this.props.getPrintOrders({
        customerGuid: window.location.pathname.split('/')[2],
        filter: 'any'
      })
      return null;
    };

    this.setState({
      filterParam
    });
    this.props.getPrintOrders({
      customerGuid: window.location.pathname.split('/')[2],
      filter: filterParam
    })
  }

  renderLabelLegend = () => {
    const { language } = this.props;
    const { filterParam } = this.state;

    return (
      <div className="label-legend">
        <span className='t t--grey'>{getTranslate(language, 'printList.filterSetup')}: </span>
        <button onClick={this.filterItems('running')} className={`label label--stroked-active ${filterParam === 'running' ? 'is-active' : null}`}>
          {getTranslate(language, 'printList.running')}
        </button>
        <button onClick={this.filterItems('released')} className={`label label--stroked-done ${filterParam === 'released' ? 'is-active' : null}`}>
          {getTranslate(language, 'printList.released')}
        </button>
        <button onClick={this.filterItems('canceled')} className={`label label--stroked-disabled ${filterParam === 'canceled' ? 'is-active' : null}`}>
          {getTranslate(language, 'printList.canceled')}
        </button>
      </div>
    )
  };

  renderPrintFileItems = () => {
    const { history, currentNavigationTab, toggleWarningModal, editedMode, currentItem, language, getAllPrintItems, getPrintItem } = this.props;

    return this.state.printOrders.map((el, index) => {
      return <PrintItem
        guid={el.guid}
        language={language}
        history={history}
        currentItem={currentItem}
        currentTab={currentNavigationTab}
        editedMode={editedMode}
        toggleWarningModal={toggleWarningModal}
        getPrintItem={getPrintItem}
        getAllPrintItems={getAllPrintItems}
        key={index}
        setNavigationTab={this.props.setNavigationTab}
        item={{
          ...el.item,
          status: el.status,
          statusDate: el.statusDate,
          guid: el.guid
        }}
      />
    })
  };

  renderHint = () => {
    const { language } = this.props;
    return (
      <div className="print-item__hint">
        <p>{getTranslate(language, 'printList.firstLoadTooltip')}</p>
        <button className="print-item__hint-button" onClick={this.toggleHint}>Okay!</button>
      </div>
    )
  };

  toggleHint = () => {
    this.setState({
      firstLoad: false
    })
  };

  handleInputReset = () => {
    this.setState({
      search: '',
      printOrders: this.filteredOrders
    })
  }

  handleInputChange = (e) => {
    const searchValue = e.target.value;

    const filteredOrders = this.filteredOrders.filter(el => {
      return (
        el.item.itemId.toLowerCase().includes(searchValue.toLowerCase())
        || el.item.itemName.toLowerCase().includes(searchValue.toLowerCase())
        || el.item.salesId.toLowerCase().includes(searchValue.toLowerCase())
        || el.status.toLowerCase().includes(searchValue.toLowerCase())
      )
    })

    this.setState({
      printOrders: filteredOrders,
      search: searchValue
    })
  };

  render() {
    const { language, history } = this.props;
    const isCanceled = false;
    return (
      <div>
        <PrintFlow history={history} isOrdersPage={true} />
        <div className="print-list print-list--orders">
          {this.state.firstLoad && !isCanceled && this.renderHint()}
          <div className="print-list__filter">
            <input
              value={this.state.search}
              placeholder={getTranslate(language, 'printList.filterPlaceholder')}
              className="print-list__filter-input"
              onChange={this.handleInputChange}
            />
            <button
              className="print-list__reset"
              onClick={this.handleInputReset}
            />
          </div>
          {this.renderLabelLegend()}
          <div className={`print-file-items ${isCanceled && 'is-canceled'}`}>
            {this.renderPrintFileItems()}
          </div>
        </div>
      </div>
    )
  }
};

const mapStateToProps = ({ Items, Navigation }) => ({
  language: Items.language,
  printItems: Items.printItems,
  currentItem: Items.item,
  printOrders: Items.printOrders,
  editedMode: Items.editedMode,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  getAllPrintItems: () => dispatch(getAllPrintItems.request()),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  getPrintOrders: (data) => dispatch(getPrintOrders.request(data)),
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
  setEditedMode: (data) => dispatch(setEditedMode(data)),
  toggleWarningModal: (data) => dispatch(toggleWarningModal(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);


