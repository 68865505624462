import React from 'react';
import { MetroSpinner } from "react-spinners-kit";
import { getTranslate } from "utils/translate";

const ProcessingBlock = ({ language }) => {
  return (
    <div>
      <div className="progress-block">
        <p className="progress-block__header">
          { getTranslate(language, 'processing.title') }
        </p>
        <div className="progress-block__body">
          <div className="progress-block__spinner">
            <MetroSpinner size={140} color="#AAC1C4" loading={true} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProcessingBlock;
