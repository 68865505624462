import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import reducers from 'reducers';

const sagaMiddleware = createSagaMiddleware();
export default function configureStore() {
  const store = createStore(
    combineReducers({
      ...reducers
    }),
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  store.runSaga = sagaMiddleware.run;
  return store
}
