import React, { Component } from 'react';
import './styles.scss';
import { connect } from "react-redux";
import { get } from 'lodash';

import { getAllPrintItems, setItemFile, getPrintItem } from "actions/Items";
import { getTranslate } from "utils/translate";

import InitialBlock from "./InitialBlock";
import StatusBlock from "./StatusBlock";
import UploadBlock from "./UploadBlock";
import LegalCheck from "./LegalCheck";
import PrintSpec from "./PrintSpec";
import ProcessingBlock from "./ProcessingBlock";
import TechCheck from "./TechCheck";
import Approval from "./Approval";

class ContentBlock extends Component {

  getContent = () => {
    const { isOrdersPage, history, printItems, currentNavigationTab, currentItem, setItemFile, language } = this.props;

    if (!isOrdersPage && get(printItems, 'case.status') === 'canceled') return (
      <StatusBlock
        title={getTranslate(language, 'canceled.title')}
        text={getTranslate(language, 'canceled.text')}
        statusType="canceled"
      />
    );

    if (isOrdersPage || (!currentItem && get(printItems, 'case.status') !== 'released')) {
      return <InitialBlock language={language} history={history} />
    }

    if (currentItem) {
      if (currentItem.itemStatus === 'processing') {
        return (
          <ProcessingBlock language={language} currentItem={currentItem} />
        )
      }

      if (currentNavigationTab === 'fileUpload') {
        return (
          <UploadBlock currentItem={currentItem} setItemFile={setItemFile} />
        )
      }
      if (currentNavigationTab === 'printSpecification') {
        return (
          <PrintSpec currentItem={this.props.currentItem} />
        )
      }

      if (currentItem.itemStatus === 'ra_check'
        || currentNavigationTab === 'regulatoryCheck'
        || (currentItem.itemStatus === 'processing' && currentItem.regulatoryCheck.status === 2)) {
        return (
          <LegalCheck currentItem={this.props.currentItem} />
        )
      }

      if (currentItem.itemStatus === 'technical_check' || currentNavigationTab === 'technicalCheck') {
        return (
          <TechCheck />
        )
      }

      if (currentItem.itemStatus === 'wait_for_release' || currentItem.itemStatus === 'released') {
        return (
          <Approval />
        )
      }
    }

    if (printItems.case.status === 'released') return (
      <StatusBlock
        title={getTranslate(language, 'released.title')}
        text={getTranslate(language, 'released.text')}
        statusType="released"
      />
    );
  };

  render() {
    return (
      <div className="content-block">
        {this.getContent()}
      </div>
    )
  }
};

const mapStateToProps = ({ Items, Navigation }) => ({
  language: Items.language,
  printItems: Items.printItems,
  currentItem: Items.item,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  getAllPrintItems: () => dispatch(getAllPrintItems.request()),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  setItemFile: (data) => dispatch(setItemFile(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentBlock);

