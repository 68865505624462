import React, { Component } from 'react';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons/faSearchPlus";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { MetroSpinner } from "react-spinners-kit";
import { get } from 'lodash';
import { getPrintItem } from "actions/Items";
import api from 'utils/api';
import { openFile } from "utils/openFile";
import { getGuidFromPath } from "utils/helpers";
import { getTranslate } from "utils/translate";
import './styles.scss';
import { Link } from "react-router-dom";

class InfoBlock extends Component {
  state = {
    activeTab: 1,
    specFileLoading: false,
    infoFileLoading: false,
    userFileLoading: false,
    dieCuttingTemplLoading: null,
  };  

  componentDidMount() {
    const ordersPage = window.location.href.includes('orders');
    if (ordersPage) {
      return this.setState({
        activeTab: 0
      })
    };

    if (!this.props.currentItem) return null;

    this.setState({
      activeTab: 1
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentItem !== prevProps.currentItem) {
      this.setState({
        activeTab: 2
      })
    }

    if (this.props.history.location.href !== prevProps.history.location.href) {
      const ordersPage = window.location.href.includes('orders');
      if (ordersPage) {
        return this.setState({
          activeTab: 0
        })
      };
    }
  }

  getUserFile = async () => {
    const payload = {
      printFileItemId: this.props.currentItem.itemId,
      guid: getGuidFromPath(),
      "specificationFile": false,
      "printFile": true,
      "regulatoryFile": false,
      "proofFile": false
    };
    this.setState({
      userFileLoading: true
    });
    const {response} = await api.downloadFile(payload);
    const file = new Blob([ response ], {type: "application/pdf"});
    this.setState({
      userFileLoading: false
    });
    openFile(file)
  };

  getInfoFile = async () => {
    this.setState({
      infoFileLoading: true
    });
    const {response} = await api.downloadInfoFile(this.props.customer.languageId);
    const file = new Blob([ response ], {type: "application/pdf"});
    this.setState({
      infoFileLoading: false
    });
    openFile(file)
  };  

  getSpecFile = async () => {
    const payload = {
      printFileItemId: this.props.printItems.printFileItems[0].itemId,
      guid: getGuidFromPath(),
      "specificationFile": true,
      "printFile": false,
      "regulatoryFile": false,
      "proofFile": false
    };

    this.setState({
      specFileLoading: true
    });
    const {response} = await api.downloadFile(payload);

    const file = new Blob([ response ], {type: "application/pdf"});
    this.setState({
      specFileLoading: false
    });

    openFile(file)
  };

  getDieCuttingTemplate = async (dieCuttingTemplateId) => {
    const payload = {
      printFileItemId: this.props.printItems.printFileItems[0].itemId,
      guid: getGuidFromPath(),
      stanzItemId: dieCuttingTemplateId,
      "specificationFile": false,
      "printFile": false,
      "regulatoryFile": false,
      "proofFile": false
    };
    
    this.setState({
      dieCuttingTemplLoading: dieCuttingTemplateId
    });
    const {response} = await api.downloadFile(payload);

    const file = new Blob([ response ], {type: "application/pdf"});
    this.setState({
      dieCuttingTemplLoading: null
    });

    openFile(file);
  }

  toggleTab = (tab) => {
    if (tab === 0 && !this.props.currentItem) {
      // this.props.getPrintOrders({
      //   customerGuid: this.props.customer.customerGuid,
      //   filter: 'any'
      // });
      return this.setState({activeTab: tab});
    } else if (tab === 2 && !this.props.currentItem) {
      if (get(this.props.printItems, 'case.status') === 'canceled') return null;
      this.selectFirstItem()
    }

    this.setState({activeTab: tab});
  };

  selectFirstItem = () => {
    if (!this.props.printItems) return null;
    this.props.getPrintItem(this.props.printItems.printFileItems[0].itemId);
  };

  renderDieCuttingTemplates = () => {
    const dieCuttingTemplates = get(this.props.printItems, "item.stanzItems");

    if(dieCuttingTemplates && dieCuttingTemplates.length > 0) {
      return dieCuttingTemplates.map((template) => {
        return (
          <button onClick={ () => this.getDieCuttingTemplate(template.itemId) } className="info-block__link info-block__link--pdf" key={template.itemId}>
            {template.name}
            <div className="info-block__spinner">
              <MetroSpinner size={ 25 } color="#AAC1C4" loading={ this.state.dieCuttingTemplLoading === template.itemId }/>
            </div>
          </button>
        );
      });
    }

    return null;
  }

  render() {
    const {printItems, customer, currentItem, language} = this.props;
    const {activeTab, infoFileLoading, userFileLoading, specFileLoading} = this.state;
    const ordersPage = window.location.href.includes('orders');

    return (
      <div className="info-block">
        <div className="info-block__tabs">
          <Link
            className={ `info-block__tab ${ activeTab === 0 && "is-active" }` }
            to={`/orders/${get(customer, 'customerGuid')}`}
              onClick={ () => this.toggleTab(0) }>
              { getTranslate(language, 'infoBlock.orderItem') }
          </Link>
          <button
            className={ `info-block__tab ${ activeTab === 1 && "is-active" }` }
            disabled={ordersPage}
            onClick={ () => this.toggleTab(1) }>
            { getTranslate(language, 'infoBlock.orderData') }
          </button>
          <button
            className={ `info-block__tab ${ activeTab === 2 && "is-active" }` }
            disabled={ordersPage}
            onClick={ () => this.toggleTab(2) }>
            { getTranslate(language, 'infoBlock.printData') }
          </button>
        </div>
        <div className="info-block__content is-active">
          { activeTab === 0 && (
            <div>
              <div className="info-block__content-part">
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.company') }:{ ' ' }
                  { get(customer, 'accountName') }
                </p>
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.customerNumber') }:{ ' ' }
                  { get(customer, 'accountNr') }
                </p>
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.contactPerson') }:{ ' ' }
                  { get(customer, 'contactPersonName') }
                </p>
              </div>
            </div>
          ) }
          { activeTab === 1 && (
            <div>
              <div className="info-block__content-part">
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.company') }:{ ' ' }
                  { get(customer, 'accountName') }
                </p>
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.customerNumber') }:{ ' ' }
                  { get(customer, 'accountNr') }
                </p>
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.contactPerson') }:{ ' ' }
                  { get(customer, 'contactPersonName') }
                </p>
              </div>
              <div className="info-block__content-part">
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.itemNumber') }:{ ' ' }
                  { get(printItems, 'item.itemId') }
                </p>
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.itemDesignation') }:{ ' ' }
                  <strong>{ get(printItems, 'item.itemName') }</strong>
                </p>
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.orderNumber') }:{ ' ' }
                  { get(printItems, 'item.salesId') }
                </p>
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.reference') }:{ ' ' }
                  { get(printItems, 'item.customerReference', 'n/a') }
                </p>
                {get(printItems, 'item.infoPrintProzess') && (
                  <p className="t t--14">
                  { getTranslate(language, 'infoBlock.additionalOrderInformation') }:{ ' ' }
                  { get(printItems, 'item.infoPrintProzess', '') }
                </p>
                )}
                <p className="t t--14">
                  { getTranslate(language, 'infoBlock.finalDate') }:{ ' ' }
                  { get(printItems, 'item.lastDate', 'n/a') }
                </p>
                <p className="t--12">{ getTranslate(language, 'infoBlock.finalDateWarning') }</p>
              </div>
              <button onClick={ this.getSpecFile } className="info-block__link info-block__link--pdf">
                { getTranslate(language, 'infoBlock.productSpecification') }
                <div className="info-block__spinner">
                  <MetroSpinner size={ 25 } color="#AAC1C4" loading={ specFileLoading }/>
                </div>
              </button>
              <button onClick={ this.getInfoFile } className="info-block__link info-block__link--pdf">
                { getTranslate(language, 'infoBlock.infoSheet') }
                <div className="info-block__spinner">
                  <MetroSpinner size={ 25 } color="#AAC1C4" loading={ infoFileLoading }/>
                </div>
              </button>
              {this.renderDieCuttingTemplates()}
            </div>
          ) }
          { activeTab === 2 && currentItem && (
            <div>
              <div className="info-block__content-part">
                <p className="t t--16 t--bold">{ currentItem.itemHeader }</p>
                <p className="t t--16">{ `${ currentItem.itemId } - ${ currentItem.itemName }` }</p>
              </div>
              <div className="info-block__content-part" style={ {marginTop: 70} }>
                <p className="t t--16 t--light t--uppercase">
                  { getTranslate(language, 'infoBlock.uploadData') }
                </p>
                {
                  currentItem.fileUpload.fileName && (
                    <div>
                      <button onClick={ this.getUserFile } className="info-block__link" style={ {marginTop: 8} }>
                        {/*<a target="_blank" href={ this.state.userFileDownloadLink } style={ {display: 'flex'} }>*/ }
                        <FontAwesomeIcon icon={ faFolder } className="header__contacts-icon"/>
                        <div>
                          <p className="t t--14">{ currentItem.fileUpload.fileName }</p>
                          <p
                            className="t t--14">{ currentItem.fileUpload.fileSize } { currentItem.fileUpload.fileDate }</p>
                        </div>
                        <div className="info-block__spinner">
                          <MetroSpinner size={ 25 } color="#AAC1C4" loading={ userFileLoading }/>
                        </div>
                      </button>
                      <button onClick={ this.getUserFile } className="info-block__link info-block__link--light">
                        <FontAwesomeIcon icon={ faSearchPlus } className="header__contacts-icon"/>
                        { getTranslate(language, 'infoBlock.show') }
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          ) }
        </div>
      </div>
    )
  }
};

const mapStateToProps = ({Items}) => ({
  currentItem: Items.item,
  language: Items.language,
  printFile: Items.printFile,
  printItems: Items.printItems,
  customer: Items.customer,
  printOrders: Items.printOrders,
});

const mapDispatchToProps = dispatch => ({
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  // getPrintOrders: (id) => dispatch(getPrintOrders.request(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoBlock);
