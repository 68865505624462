import React, { Component } from 'react';
import { connect } from "react-redux";

import { setNavigationTab } from "actions/Navigation"
import { setEditedMode, toggleWarningModal } from "actions/Items";
import { getTranslate } from "utils/translate"

import './styles.scss';

class Navigation extends Component {
  handleNavItemClick = (e) => {
    const {editedMode, toggleWarningModal} = this.props;
    if (editedMode) {
      return toggleWarningModal({
        warningModalOpen: true,
        nextTab: e.target.name
      })
    }
    this.props.setNavigationTab(e.target.name)
  };

  getStatus = (process) => {
    const {currentItem, currentNavigationTab} = this.props;

    if (!currentItem) return 'navigation__item is-disabled';
    if (currentItem.itemStatus === 'processing') return 'navigation__item is-disabled';
    if (currentNavigationTab === process) return 'navigation__item is-active';
    switch (currentItem[process].status) {
      case 0:
        if (process === 'technicalCheck' && currentItem.itemStatus === 'technical_check') return 'navigation__item'
        if (process === 'regulatoryCheck' && currentItem.itemStatus === 'ra_check') return 'navigation__item'
        return 'navigation__item is-disabled';
      case 2:
        return 'navigation__item is-done';
      default:
        return 'navigation__item';
    }
  };

  render() {
    const {language} = this.props;

    return (
      <div className="navigation">
        <button
          className={ this.getStatus("fileUpload") }
          name="fileUpload"
          onClick={ this.handleNavItemClick }>
          <span className="navigation__item-count">1</span>
          { getTranslate(language, 'navigation.fileUpload') }
        </button>
        <button
          className={ this.getStatus("printSpecification") }
          name="printSpecification"
          onClick={ this.handleNavItemClick }>
          <span className="navigation__item-count">2</span>
          { getTranslate(language, 'navigation.printExecution') }
        </button>
        <button
          className={ this.getStatus("regulatoryCheck") }
          name="regulatoryCheck"
          onClick={ this.handleNavItemClick }>
          <span className="navigation__item-count">3</span>
          { getTranslate(language, 'navigation.legalCheck') }
        </button>
        <button
          className={ this.getStatus("technicalCheck") }
          name="technicalCheck"
          onClick={ this.handleNavItemClick }>
          <span className="navigation__item-count">4</span>
          { getTranslate(language, 'navigation.technicalCheck') }
        </button>
        <button
          className={ this.getStatus("releaseCheck") }
          onClick={ this.handleNavItemClick }
          name="releaseCheck">
          <span className="navigation__item-count">5</span>
          { getTranslate(language, 'navigation.printApproval') }
        </button>
      </div>
    );
  }
};

const mapStateToProps = ({Items, Navigation}) => ({
  language: Items.language,
  printItems: Items.printItems,
  editedMode: Items.editedMode,
  currentItem: Items.item,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
  setEditedMode: (data) => dispatch(setEditedMode(data)),
  toggleWarningModal: (data) => dispatch(toggleWarningModal(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
