import React, {Component} from 'react';
import { connect } from "react-redux";

import info from 'assets/images/info-small.svg';
import { getTranslate, getTranslateDyn } from "utils/translate";
import { MAX_CHARS_REMARK } from 'utils/constants';
import { getGuidFromPath } from "utils/helpers";

import { addFileComment, getPrintItem, setEditedMode, toggleWarningModal } from "actions/Items";
import {setNavigationTab} from "actions/Navigation";

import WarningModal from "../WarningModal";
import DropZone from "./DropZone";
import RemarkField from 'components/RemarkField';

class UploadBlock extends Component {
  state = {
    commentWindowVisible: false,
    comment: '',
  }

  componentDidMount() {
    const {currentItem} = this.props;
    this.setState({
      comment: currentItem && currentItem.fileUpload.comment,
      commentWindowVisible: currentItem && currentItem.fileUpload.fileName
    })
  }

  componentDidUpdate(prevProps) {
    const {currentItem} = this.props;

    if (currentItem !== prevProps.currentItem) {
      this.setState({
        comment: currentItem && currentItem.fileUpload.comment,
        commentWindowVisible: currentItem && currentItem.fileUpload.fileName
      })
    }
  }

  addFileComment = () => {
    if (this.state.comment && this.state.edited) {
      const payload = {
        comment: this.state.comment,
        printFileItemId: this.props.currentItem.itemId,
        guid: getGuidFromPath(),
      };

      this.props.addFileComment(payload);
    }

    this.props.setEditedMode(false);
    this.props.setNavigationTab('printSpecification')
    this.setState({
      edited: false
    })
  };

  showCommentWindow = () => {
    this.setState({
      commentWindowVisible: true,
    })
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })

    if (!this.state.edited) {
      this.props.setEditedMode(true);
      this.setState({
        edited: true
      })
    }
  };

  render() {
    const {language, currentItem, warningModalOpen} = this.props;
    const {commentWindowVisible, comment} = this.state;
    return (
      <div className={(commentWindowVisible || comment) ? 'upload-block upload-block--extra-height' : 'upload-block'}>
        { warningModalOpen && <WarningModal /> }
        <p className="content-block__title content-block__title--upload">
          { getTranslate(language, 'fileUpload.title') }
        </p>
        <DropZone showCommentWindow={this.showCommentWindow} />
        <div className="upload__info">
          <img src={info} alt="" className="upload__info-icon"/>
          <p className="t t--centered t--14">
            { getTranslate(language, 'fileUpload.dndText') }
          </p>
        </div>
        { (commentWindowVisible || comment) && (
          <div>
            <div className="content-block__textarea-wrapper">
              <p className="t t--12"
                 style={ {marginLeft: 20} }>
                <span className="t--uppercase">{ getTranslate(language, 'printExecution.message') }</span>&nbsp;
                { getTranslateDyn(language, 'remarkField.maxCharacters', ['$NUMBER$'], [String(MAX_CHARS_REMARK)]) }
              </p>
              <RemarkField
                className="content-block__textarea"
                name="comment"
                value={comment}                
                placeholder={getTranslate(language, 'fileUpload.commentPlaceholder')}
                disabled={
                  currentItem.itemStatus !== 'wait_for_upload'
                  && currentItem.fileUpload.status === 2
                  && currentItem.regulatoryCheck
                }
                maxLength={MAX_CHARS_REMARK}
                onChange={ this.onChange }                
              />
            </div>
            { currentItem.itemStatus === 'wait_for_upload' && (
              <button
                onClick={ this.addFileComment }
                className="button button--bottom button--round">
                { getTranslate(language, 'fileUpload.confirm') }
              </button>
            ) }
          </div>
        ) }
      </div>
    )
  }
};

const mapStateToProps = ({Items}) => ({
  language: Items.language,
  warningModalOpen: Items.warningModalOpen,
  currentItem: Items.item,
  successModalOpen: Items.item
});

const mapDispatchToProps = dispatch => ({
  addFileComment: (data) => dispatch(addFileComment.request(data)),
  setEditedMode: (data) => dispatch(setEditedMode(data)),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  toggleWarningModal: (data) => dispatch(toggleWarningModal(data)),
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadBlock);

