import React, { Component } from 'react';
import { connect } from "react-redux";
import { get } from 'lodash';

import { getAllPrintItems, getPrintItem, setEditedMode, toggleWarningModal, resetPrintItems } from "actions/Items";
import { setNavigationTab } from "actions/Navigation";
import { getTranslate } from "utils/translate";
import './styles.scss';

import PrintItem from "./PrintItem";
import PrintFlow from "components/PrintFlow";

class PrintList extends Component {
  state = {
    firstLoad: false,
    preselectPrintItem: ""
  };  

  componentDidMount() {

    const urlParams = new URLSearchParams(this.props.location.search);

    this.props.resetPrintItems();
    this.props.setNavigationTab('');
    this.props.getAllPrintItems(this.props.location.pathname.split('/')[1]);

    if(urlParams.has("itemId")) {      
      this.setState({preselectPrintItem: urlParams.get("itemId")});      
    }    

    if (localStorage.getItem("isFirstLoad") === null) {
      this.setState({
        firstLoad: true
      });
      localStorage.setItem("isFirstLoad", false);
    }
  }

  renderLabelLegend = () => {
    const { language } = this.props;
    return (
      <div className="label-legend">
        <div className="label-legend__item">
          <div className="label label--small label--active">{getTranslate(language, 'printList.orange')}</div>
          <span className="t t--12">{getTranslate(language, 'printList.open')}</span>
        </div>
        <div className="label-legend__item">
          <div className="label label--small label--done">{getTranslate(language, 'printList.green')}</div>
          <span className="t t--12">{getTranslate(language, 'printList.finished')}</span>
        </div>
        <div className="label-legend__item">
          <div className="label label--small label--disabled">{getTranslate(language, 'printList.grey')}</div>
          <span className="t t--12">{getTranslate(language, 'printList.inactive')}</span>
        </div>
      </div>
    )
  };

  renderPrintFileItems = () => {
    const { printItems, currentNavigationTab, toggleWarningModal, editedMode, currentItem, language, getAllPrintItems, getPrintItem } = this.props;

    if (!printItems.printFileItems) return null;
    return printItems.printFileItems.map((el) => {
      return <PrintItem
        guid={printItems.case.guid}
        language={language}
        currentItem={currentItem}
        currentTab={currentNavigationTab}
        editedMode={editedMode}
        toggleWarningModal={toggleWarningModal}
        getPrintItem={getPrintItem}
        getAllPrintItems={getAllPrintItems}
        key={el.itemId}
        setNavigationTab={this.props.setNavigationTab}
        preselectPrintItem={this.state.preselectPrintItem}
        item={el} />
    })
  };

  renderHint = () => {
    const { language } = this.props;
    return (
      <div className="print-item__hint">
        <p>{getTranslate(language, 'printList.firstLoadTooltip')}</p>
        <button className="print-item__hint-button" onClick={this.toggleHint}>Okay!</button>
      </div>
    )
  };

  toggleHint = () => {
    this.setState({
      firstLoad: false
    })
  };


  render() {
    const { printItems, history } = this.props;
    if (!printItems) return null;
    const isCanceled = get(printItems, 'case.status') === 'canceled';

    return (
      <div className="container">
        <PrintFlow history={history} printItems={printItems} />
        <div className={`print-list ${isCanceled && 'is-disabled'}`}>
          {this.state.firstLoad && !isCanceled && this.renderHint()}
          {this.renderLabelLegend()}
          <div className={`print-file-items ${isCanceled && 'is-canceled'}`}>
            {this.renderPrintFileItems()}
          </div>
        </div>
      </div>
    )
  }
};

const mapStateToProps = ({ Items, Navigation }) => ({
  language: Items.language,
  printItems: Items.printItems,
  currentItem: Items.item,
  editedMode: Items.editedMode,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  getAllPrintItems: (id) => dispatch(getAllPrintItems.request(id)),
  getPrintItem: (id) => dispatch(getPrintItem.request(id)),
  setNavigationTab: (tab) => dispatch(setNavigationTab(tab)),
  setEditedMode: (data) => dispatch(setEditedMode(data)),
  toggleWarningModal: (data) => dispatch(toggleWarningModal(data)),
  resetPrintItems: () => dispatch(resetPrintItems())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintList);


